@import "styles/variables.scss";
@import "styles/mixins.scss";

:export {
  primaryColor: $primary-color;
}

h1 {
  @extend %GradientText;

  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 10px;
}

h3 {
  color: $primary-text-color;
  letter-spacing: 1.2px;
  margin: 15px 0px;

  font-size: 20px;

  @include media(">phone") {
    font-size: 25px;
  }
}

p {
  color: $secondary-text-color;
  letter-spacing: 1.2px;
  line-height: 140%;
  margin: 10px 0px;

  font-size: 17px;

  @include media(">phone") {
    font-size: 20px;
  }
}

// https://fossheim.io/writing/posts/css-text-gradient/
%GradientText {
  /* Fallback: Set a background color. */
  background-color: #ba863b;

  /* Create the gradient. */
  background-image: linear-gradient(
    $gradient-text-color-1,
    $gradient-text-color-2,
    $gradient-text-color-1
  );
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.PageContainer {
  width: 100%;
  padding-top: 10px;
  // padding-bottom: 50px;

  @include media("<tablet") {
    // padding-bottom: 120px;
  }
}

.Link {
  letter-spacing: 1px;
  text-decoration: none;
  color: $primary-text-color;
  font-style: oblique;
  font-weight: $font-weight-bold;

  &:visited,
  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.YoutubeVideo {
  $yt-small-width-pct: 90%;
  $yt-medium-width-pct: 70%;
  $yt-large-width-pct: 80%;
  $yt-desktop-width-pct: 70%;

  margin: 15px auto;

  width: $yt-small-width-pct;

  @include media(">=medium") {
    width: $yt-medium-width-pct;
  }
  @include media(">=desktop") {
    width: $yt-desktop-width-pct;
  }
  @include media(">=large") {
    width: $yt-large-width-pct;
  }
}

.TwitchVideo {
  margin: 15px auto !important;

  padding-top: 50.75% !important;
  width: 90% !important;
  @include media(">=medium") {
    padding-top: 39.5% !important;
    width: 70% !important;
  }
  @include media(">=desktop") {
    padding-top: 39.5% !important;
    width: 70% !important;
  }
  @include media(">=large") {
    padding-top: 45% !important;
    width: 80% !important;
  }
}

.Time {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: $font-weight-bold;
}

.PeepoShy {
  height: 35px;
}

.WidepeepoHappyImg {
  width: 70px;
}

.Center {
  display: flex;
  justify-content: center;
}

.Left {
  display: flex;
  justify-content: left;
}

.Right {
  display: flex;
  justify-content: right;
}
