@import "styles/mixins.scss";

.Video {
  position: relative;

  iframe {
    aspect-ratio: 16/9;
    display: block;
    width: 100%;
  }

  .OverlayImg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;

    // To skip pointer events and let them go through to the underlying element instead.
    pointer-events: none;
  }
}
