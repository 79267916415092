@import "styles/variables.scss";
@import "styles/mixins.scss";

:export {
  desktopWindowWidthPx: 1080;
}

.TicketsModal {
  max-width: 500px !important;

  @include media(">desktop") {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.Tickets {
  margin: auto;
  color: $secondary-text-color;

  p {
    padding: 5px 0px;
  }

  a {
    text-decoration: none;

    :link,
    :visited,
    :active {
      text-decoration: none;
    }
  }

  .Buttons {
    margin-top: 15px;

    .CloseBtn {
      margin-right: 5px;

      * {
        font-size: 14px;

        @include media(">=phone") {
          font-size: 16px;
        }
      }
    }
  }
}
