@import "styles/variables.scss";
@import "styles/mixins.scss";

.BtnContainer {
  display: flex;

  &.Left {
    justify-content: flex-start;
  }
  &.Center {
    justify-content: center;
  }
  &.Right {
    justify-content: flex-end;
  }

  * {
    color: $primary-text-color;

    :active {
      text-decoration: none;
    }

    :link {
      text-decoration: none;
    }

    :visited {
      text-decoration: none;
    }
  }
}

.Btn {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;

  padding: 10px;

  border: none;
  background-color: transparent;
  color: $primary-text-color;
  font-family: $font;

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &:disabled,
  button[disabled] {
    cursor: default;
    color: #666666;
    background-color: unset;
  }

  @include media(">=phone") {
    font-size: 18px;
  }
}
