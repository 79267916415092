@import "styles/variables.scss";

.AuthContainer {
  font-size: 16px;
  letter-spacing: 1px;

  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 15px;

  p {
    margin: 0px;
    margin-bottom: 10px;
    line-height: 160%;
  }

  .User {
    font-weight: $font-weight-bold;
  }

  a {
    text-decoration: none;

    :link,
    :visited,
    :active {
      text-decoration: none;
    }
  }
}

.AuthBtn {
  background-color: $auth-btn-color-1;
  display: inline-flex;
  align-items: center;
  color: $auth-btn-secondary-color;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: $font-weight-bold;
  font-family: $font;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:active {
    background-color: $auth-btn-color-2;
    cursor: pointer;
    opacity: 1;
  }

  .BtnIcon {
    margin-right: 10px;
    background-color: $auth-btn-secondary-color;
    padding: 10px;
    border-radius: 2px;
  }

  .BtnText {
    padding: 10px 10px 10px 0px;
  }
}
