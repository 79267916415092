@import "styles/mixins.scss";

:root {
  --checkmark-fill-color: green;
  --checkmark-border-color: green;
}

$checkmark-arrow-color: #fff;
$checkmark-arrow-thickness: 5;

$checkmark-border-thickness: 10;

.Checkmark {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  stroke: $checkmark-arrow-color;
  stroke-width: $checkmark-arrow-thickness;
  stroke-miterlimit: 10;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;

  width: 10px;
  height: 10px;

  @include media(">=small") {
    width: 15px;
    height: 15px;
  }
  @include media(">=phone") {
    width: 18px;
    height: 18px;
  }
  @include media(">=tablet") {
    width: 20px;
    height: 20px;
  }

  .CheckmarkCircle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: $checkmark-border-thickness;
    stroke-miterlimit: 10;
    stroke: var(--checkmark-border-color);
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .CheckmarkCheck {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 100vh var(--checkmark-fill-color);
  }
}
