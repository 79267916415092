@import "styles/variables.scss";
@import "styles/mixins.scss";

.Container {
  margin: auto;
  margin-bottom: 30px;

  max-width: 1300px;
  color: $secondary-text-color;

  @include media("<=medium") {
    padding: 0px 15px;
  }

  @include media(">medium") {
    display: flex;
    align-items: center;
    width: 100%;
  }

  @include media(">desktop") {
    width: 90%;
  }
}

.Intro {
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: 0px 15px;

  @include media(">phone") {
    padding-left: 30px;
    padding-right: 30px;
  }

  .Title {
    font-size: 50px;
    font-weight: $font-weight-bold;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;

    margin: 0px;

    @include media(">=medium") {
      font-size: 60px;
    }
  }

  .Description {
    font-size: 25px;
    letter-spacing: 1px;

    text-align: center;

    margin-bottom: 20px;

    @include media(">=medium") {
      font-size: 30px;
    }
  }

  .BtnGroup {
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;

    display: flex;
    align-items: center;
    flex-direction: column;

    @include media(">=tablet") {
      font-size: 16px;
    }

    :link,
    :visited,
    :active {
      text-decoration: none;
    }
  }
}
