@import "styles/variables.scss";
@import "styles/mixins.scss";

// Adapted from https://moderncss.dev/custom-select-styles-with-pure-css/
.Select {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  padding: 0 3px;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  color: $primary-text-color;

  outline: none;

  border: none;
  border-bottom: 2px solid;

  transition: all 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &::-ms-expand {
    display: none;
  }

  .Option {
    // color: black;
  }
}
