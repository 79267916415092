@import "styles/variables.scss";
@import "styles/mixins.scss";

.FAQPage {
  h1 {
    font-size: 35px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    padding: 0px 5px;

    margin: 0px;
    margin-bottom: 10px;

    @include media(">phone") {
      font-size: 50px;
    }
  }

  h2 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: $font-weight-faux-bold;
    letter-spacing: 1px;

    margin: 5px;

    @include media(">phone") {
      font-size: 30px;
    }
  }

  h4 {
    font-size: 17px;
    letter-spacing: 1.2px;
    line-height: 140%;

    @include media(">phone") {
      font-size: 20px;
    }
  }

  p {
    font-size: 17px;
    letter-spacing: 1.2px;
    line-height: 140%;

    @include media(">phone") {
      font-size: 20px;
    }
  }

  .Time {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 50;
  }

  .DividerImg {
    width: 400px;
    max-width: 95%;

    margin: 15px 0px;
  }

  .Section {
    padding: 10px 5px;

    ul {
      margin: auto;
      padding: 0px;

      width: 90%;
      max-width: 750px;

      @include media(">phone") {
        width: 85%;
      }
      @include media(">large") {
        width: 80%;
      }

      li {
        list-style: none;

        &:last-child {
          p {
            margin-bottom: 0px;
          }
        }

        .Question {
          margin-bottom: 5px;
          font-style: italic;
        }

        .Answer {
          margin-top: 5px;
          color: $secondary-text-color;

          li {
            list-style: unset;
          }

          .Highlight {
            color: $primary-text-color;
          }
        }
      }
    }
  }
}
