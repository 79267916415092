@import "styles/mixins.scss";
@import "styles/variables.scss";

.WidepeepoHappyImg {
  width: 70px;
}

.Success {
  margin: auto;
  padding: 0px 10px;
  width: 80%;

  h3 {
    margin: 0px;
  }
}

.BackBtnContainer {
  margin-left: 10px;
}

.Description {
  padding: 0px 20px;
  width: 80%;
  margin: auto;

  h2 {
    text-transform: none;
  }

  .Criteria {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    font-size: 17px;
    text-align: left;
    color: $secondary-text-color;
    max-width: 1000px;
    margin: auto;

    @include media(">phone") {
      font-size: 19px;
    }

    b {
      font-weight: 100;
      font-family: $font-bold;
      color: $primary-text-color;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .Disclaimer {
    letter-spacing: 1px;
    font-size: 15px;

    width: 80%;
    margin: auto;
    padding: 5px 20px;

    @include media("<=medium") {
      width: 80%;
    }
    @include media("<=phone") {
      padding: 5px 0px;
      width: 95%;
    }
  }
}

.FormError {
  width: 60%;
  padding-top: 30px;
  padding-bottom: 20px;
  margin: auto;

  font-size: 24px;

  @include media("<=phone") {
    width: 70%;
    font-size: 20px;
  }
}
