@import "styles/variables.scss";
@import "styles/mixins.scss";

.ModalPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: block;
  z-index: $z-index-modal;
  backdrop-filter: blur(2px);

  &.Hide {
    backdrop-filter: blur(0px);
    display: none;
  }

  .Modal {
    position: fixed;
    background: $modal-color;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border: 1px solid $primary-color; // rgb(43, 41, 41);
    // border-image-source: url("/components/modals/assets/border.png");
    // border-image-repeat: repeat;
    // border-image-slice: 30;

    // transition: 1.1s ease-out;
    box-shadow: -1rem 1rem 1rem rgba(0, 0, 0, 0.5);

    padding: 20px;

    max-width: 700px;
    width: 80%;

    @include media(">desktop") {
      width: 50%;
    }

    .CloseContainer {
      position: relative;

      .Close {
        cursor: pointer;
        display: inline-block;
        z-index: $z-index-modal-close;

        position: absolute;
        top: -20px;
        right: -20px;

        @include media(">desktop") {
          right: -30px;
        }
      }
    }
  }
}
