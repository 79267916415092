@import "styles/variables.scss";
@import "styles/mixins.scss";

$phase-img-width: 450px;

%AboutContainer {
  margin: auto;
  width: 90%;

  width: 90%;
  max-width: 750px;

  @include media(">phone") {
    width: 85%;
  }
  @include media(">large") {
    width: 80%;
  }
}

.AboutPage {
  h1 {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    margin: 0px;
    margin-bottom: 10px;

    @include media(">phone") {
      font-size: 50px;
    }
  }

  h2 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: $font-weight-faux-bold;
    letter-spacing: 1px;

    margin: 5px;

    @include media(">phone") {
      font-size: 30px;
    }
  }

  p {
    margin: 20px 0px;
  }

  .SectionContainer {
    padding: 0px 5px;
  }

  .DividerImg {
    width: 400px;
    max-width: 95%;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  .VideosGroup {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .AboutShow {
    @extend %AboutContainer;
  }

  .AboutCreator {
    @extend %AboutContainer;

    p {
      margin: 10px 0px;
    }

    .AboutContainer {
      padding: 15px 0px;
    }

    .Img {
      width: 80%;

      @include media(">phone") {
        width: 70%;
      }

      @include media(">medium") {
        width: 60%;
      }

      @include media(">desktop") {
        width: 50%;
      }
    }

    .SocialsContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .SocialImg {
        width: 40px;
        margin: 0px 20px;
      }
    }
  }

  .HowItWorksContainer {
    padding: 0px;
    margin-bottom: 30px;

    @extend %AboutContainer;

    .HowItWorksItem {
      margin: auto;
      list-style: none;

      margin-bottom: 30px;
    }

    .Btn {
      text-decoration: none;
      margin-top: -10px;

      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;

      padding: 10px;

      border: none;
      background-color: transparent;
      color: $primary-text-color;
      font-family: $font;
      font-weight: $font-weight-bold;

      &:hover {
        cursor: pointer;
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.05);
      }

      @include media(">=phone") {
        font-size: 18px;
      }
    }
  }

  .PhaseImg {
    width: $phase-img-width;
    max-width: 100%;

    margin-top: 10px;
    margin-bottom: 10px;
  }

  .WeightageImg {
    width: $phase-img-width;
    max-width: 100%;
    margin-top: 10px;
  }
}
