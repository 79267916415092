$primary-color: #ba863b;

$primary-text-color: $primary-color;
$secondary-text-color: #e5e5e5;

$modal-color: #120f0e; // #241f1d;

$navbar-text-color-1: #e1b878;
$navbar-text-color-2: #c79548;

$gradient-text-color-1: #9a6f34;
$gradient-text-color-2: #f0c463;

$auth-btn-color-1: #9a6f34;
$auth-btn-color-2: #c79548;
$auth-btn-secondary-color: #ffffff;

$text-input-text-color: $secondary-text-color;
$radio-input-text-color: $secondary-text-color;
$checkbox-input-text-color: $secondary-text-color;

$font: "proxima_novaregular", sans-serif;
$font-bold: "proxima_novabold", sans-serif;

$font-weight-faux-bold: 400;
$font-weight-bold: 600;

$z-index-bg-vid: -2;
$z-index-bg-img: -1;
$z-index-radio-play: 1;
$z-index-sticky-alert: 2;
$z-index-submit-btn: 5;
$z-index-modal: 10;
$z-index-modal-close: 11;
$z-index-intro-1: 15;
$z-index-intro-2: 16;
$z-index-intro-3: 17;
