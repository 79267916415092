@import "styles/variables.scss";
@import "styles/mixins.scss";

.AlertContainer {
  padding: 5px 0px;
  margin: 2px 0px;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  .Text {
    width: 90%;
    margin: 5px auto;
    padding: 0 15px;
    max-width: 1100px;

    font-size: 18px;

    @include media(">=phone") {
      font-size: 20px;
    }
  }
}
