@import "styles/variables.scss";
@import "styles/mixins.scss";

.TextContainer {
  display: flex;
  align-items: center;

  position: relative;
}

.Text {
  // aspect-ratio: 1789/205;
  aspect-ratio: 2400/276;

  margin: 0px;
  padding: 0px;
  padding-left: 20px;
  padding-right: 35px;
  box-sizing: border-box; // https://stackoverflow.com/a/5219611/8802161

  // display: block;

  font-family: $font;
  text-align: center;
  text-transform: uppercase;
  color: $text-input-text-color;

  background-color: rgba(0, 0, 0, 0.05);
  background-image: url("/components/form/input/assets/nomination.png");
  background-size: contain;
  width: 100%;

  border-width: 0px;
  outline: none;

  font-size: 10px;

  &::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }
  &::placeholder {
    /* Recent browsers */
    text-transform: none;
  }

  background-color: rgba(255, 255, 255, 0.03);

  @include media(">=small") {
    font-size: 18px;
  }

  @include media(">=phone") {
    font-size: 25px;
    padding-left: 30px;
    padding-right: 40px;
  }

  @include media(">=tablet") {
    font-size: 36px;
    // max-width: 600px;

    // background-image: url("nomination.png");
  }

  &:not(:placeholder-shown) {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
}

// https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
// Change autocomplete styles in WebKit
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  margin: 0px;
  border: 1.5px solid $primary-color;
  -webkit-text-fill-color: $text-input-text-color;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 1) inset;
  transition: background-color 5000s ease-in-out 0s;

  padding-left: 20px;
  padding-right: 35px;

  @include media(">=phone") {
    padding-left: 30px;
    padding-right: 40px;
  }
}

// https://stackoverflow.com/a/4298216/8802161
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.ValidInput {
  position: absolute;
  right: 15px;
}
