@import "styles/variables.scss";
@import "styles/mixins.scss";

$door-animation-length-ms: 2500;
$elevator-animation-length-ms: 1000;

$door-transition: all (#{$door-animation-length-ms}ms) ease-in;
$elevator-transition: all (#{$elevator-animation-length-ms}ms) ease-in;

$approxPillarWidth: 310px;
$approxPillarWidthSmall: 230px;

$approxElevatorWidth: 110px;
$approxElevatorWidthSmall: 80px;

$approxDoorMaxWidth: $approxPillarWidth + $approxElevatorWidth;
$approxDoorMaxWidthSmall: $approxPillarWidthSmall + $approxElevatorWidthSmall;

$bgImg: url("/assets/background.png") #241f1d;

$door-width-percent-large: 30%;
$door-width-percent-small: 20%;

$door-width-percent: $door-width-percent-large;

@include media("<large") {
  $door-width-percent: $door-width-percent-small;
}

:export {
  largeWindowWidthPx: 1400;

  doorAnimationMillis: $door-animation-length-ms;
  elevatorAnimationMillis: $elevator-animation-length-ms;

  doorWidthPercentLarge: $door-width-percent-large;
  doorWidthPercentSmall: $door-width-percent-small;

  initialPillarWidthLargePx: $approxPillarWidth;
  initialPillarWidthSmallPx: $approxPillarWidthSmall;
}

:root {
  --door-width: Max($door-width-percent, $approxDoorMaxWidth);
  --pillar-width: Max($door-width-percent, $approxDoorMaxWidth);
  --elevator-width: $approxElevatorWidth;
}

%BackgroundImg {
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: $z-index-bg-img;

  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.Bg {
  @extend %BackgroundImg;

  background: $bgImg;
}

.AppContainer {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;

  font-size: calc(10px + 2vmin);
  color: $primary-text-color;

  max-width: calc(100% - 2 * var(--door-width));

  @include media("<desktop") {
    max-width: 100%;
  }
}

.Countdown {
  padding-top: 10px;
  font-size: 26px;

  @include media("<tablet") {
    font-size: 22px;
  }

  .CountdownVal {
    font-size: 32px;
    font-weight: $font-weight-bold;

    line-height: 140%;

    @include media("<tablet") {
      font-size: 24px;
    }
  }
}

.Logo {
  max-width: 95%;
  width: 400px;

  @include media(">phone") {
    width: 450px;
  }
  @include media(">tablet") {
    width: 550px;
  }
  @include media(">medium") {
    width: 600px;
  }
}

.StickyBottom {
  position: sticky;
  bottom: 0;
  z-index: $z-index-sticky-alert;

  .BottomAlert {
    background-color: rgba(0, 0, 0, 0.9);
    // position: fixed;
    // bottom: -12px;

    margin: 0px;

    p {
      margin: 5px;
    }
  }
}

video {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-bg-vid;

  width: 100%;

  opacity: 0.15;
  object-fit: cover;
}

.Doors {
  @include media("<desktop") {
    display: none;
  }

  .LeftHalfStart {
    display: flex;
    justify-content: flex-end;

    z-index: $z-index-intro-3;
    height: 100%;
    position: fixed;
    background: $bgImg;

    width: 50%;
    right: 50%;
    max-width: 50%;
  }

  .LeftHalfTransition {
    transition: $door-transition;

    // https://stackoverflow.com/a/52338132/8802161
    translate: calc(-100% + var(--door-width) + var(--elevator-width));
  }

  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_animated_properties
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Transitions
  // https://stackoverflow.com/questions/10247255/css-transition-between-left-right-and-top-bottom-positions

  .LeftHalfDone {
    z-index: $z-index-intro-3;
    height: 100%;
    position: fixed;
    background-image: none;

    // pointer-events: none;
    right: 100%;
    transform: translate(100%, 0);

    max-width: fit-content; // https://stackoverflow.com/a/38643863/8802161
    width: calc(var(--door-width) + var(--elevator-width));

    .LeftPillar,
    .LeftElevator {
      background: $bgImg;
    }
  }

  .RightHalfStart {
    display: flex;
    justify-content: flex-start;

    z-index: $z-index-intro-3;
    height: 100%;
    position: fixed;
    background: $bgImg;

    width: 50%;
    left: 50%;
    max-width: 50%;
  }

  .RightHalfTransition {
    transition: $door-transition;

    translate: calc(100% - var(--door-width) - var(--elevator-width));
  }

  .RightHalfDone {
    z-index: $z-index-intro-3;
    height: 100%;
    position: fixed;
    background-image: none;

    left: 100%;
    transform: translate(-100%, 0);

    max-width: fit-content;
    width: calc(var(--door-width) + var(--elevator-width));

    .RightPillar,
    .RightElevator {
      background: $bgImg;
    }
  }

  .LeftHalfContainer {
    position: relative;
    height: 100%;

    display: flex;
    justify-content: flex-end;
  }

  .RightHalfContainer {
    position: relative;
    height: 100%;

    display: flex;
    justify-content: flex-start;
  }

  %ImgElement {
    height: 100%;
  }

  .LeftPillar {
    @extend %ImgElement;

    right: 0px;
    z-index: $z-index-intro-2;
  }

  .RightPillar {
    @extend %ImgElement;

    left: 0px;
    z-index: $z-index-intro-2;
  }

  .LeftElevator {
    @extend %ImgElement;

    right: 0px;
    z-index: $z-index-intro-1;
  }

  .LeftElevatorTransition {
    transform: translateX(-100%);

    transition: $elevator-transition;
  }

  .LeftElevatorDone {
    display: none;
  }

  .RightElevator {
    @extend %ImgElement;

    left: 0px;
    z-index: $z-index-intro-1;
  }

  .RightElevatorTransition {
    transform: translateX(100%);

    transition: $elevator-transition;
  }

  .RightElevatorDone {
    display: none;
  }
}

.Link {
  text-decoration: none;

  :link,
  :visited,
  :active {
    text-decoration: none;
  }
}
