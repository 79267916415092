@import "styles/variables.scss";
@import "styles/mixins.scss";

$translate-percent: 50%;
$category-animation-length-ms: 400ms;

.Right .CategoryEnter {
  opacity: 0;
  transform: translateX($translate-percent);
}

.Right .CategoryEnterActive {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity $category-animation-length-ms,
    transform $category-animation-length-ms ease-in-out;
}

.Right .CategoryExit {
  opacity: 1;
  transform: translateX(0%);
}

.Right .CategoryExitActive {
  opacity: 0;
  transform: translateX(-$translate-percent);
  transition: opacity $category-animation-length-ms,
    transform $category-animation-length-ms ease-in-out;
}

.Left .CategoryEnter {
  opacity: 0;
  transform: translateX(-$translate-percent);
}

.Left .CategoryEnterActive {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity $category-animation-length-ms,
    transform $category-animation-length-ms ease-in-out;
}

.Left .CategoryExit {
  opacity: 1;
  transform: translateX(0%);
}

.Left .CategoryExitActive {
  opacity: 0;
  transform: translateX($translate-percent);
  transition: opacity $category-animation-length-ms,
    transform $category-animation-length-ms ease-in-out;
}

.Form {
  padding-top: 10px;
  padding-bottom: 40px;

  @include media(">=phone") {
    padding-bottom: 60px;
  }
}

.CategoryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media("<=tablet") {
    justify-content: space-around;
  }
}

.Category {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;

  &.Bkgd {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.CategoryIdx {
  font-size: 18px;
  letter-spacing: 1px;
  color: $secondary-text-color;

  margin: 0px;
}

.Title {
  font-size: 44px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $primary-text-color;

  margin: 10px 20px;

  @include media("<tablet") {
    font-size: 32px;
    margin: 10px 10px;
  }
}

.Description {
  font-size: 18px;
  letter-spacing: 1px;
  color: $secondary-text-color;

  margin-top: 0px;
  margin-bottom: 30px;
}

// .InputContainer {
//   display: flex;
//   justify-content: center;
// }

.Input {
  margin: 0px auto;
  width: 80%;
  max-width: 600px;
  // padding: 0 20px;

  @include media("<tablet") {
    width: 85%;
  }
}

%CategoryNavigationBtn {
  background-size: cover;
  height: 73px;
  aspect-ratio: 111/165; //37/55

  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }

  @include media("<=tablet") {
    height: 50px;

    margin-left: 5px;
    margin-right: 5px;
  }
}

.CategoryBtns {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  margin-top: 20px;
}

.BtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SkipBtnContainer {
  padding-top: 10px;
}

.SubmitAllContainer {
  z-index: $z-index-submit-btn;

  position: fixed;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);

  @include media(">=phone") {
    bottom: 20px;
  }

  // if there's a bottom alert
  bottom: 85px;
  @include media(">=phone") {
    bottom: 85px;
  }
  @include media(">=tablet") {
    bottom: 65px;
  }
}

.SubmitBtn {
  font-size: 18px;
  width: 220px;

  font-weight: $font-weight-bold;

  @include media(">=phone") {
    font-size: 20px;
    width: 250px;
  }

  @include media(">medium") {
    font-size: 24px;
    width: 270px;
  }

  span {
    width: auto;
  }

  .LoadingWrapper {
    position: relative;
    // padding-left: 20px;
    width: 15px;
    height: 15px;

    @include media(">=phone") {
      width: 20px;
      height: 20px;
    }
  }
}
