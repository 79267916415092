@import "styles/variables.scss";
@import "styles/mixins.scss";

.CategoryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media("<=tablet") {
    justify-content: space-around;
  }
}

.Category {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;

  &.Bkgd {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.CategoryIdx {
  font-size: 18px;
  letter-spacing: 1px;

  margin: 0px;
  color: $secondary-text-color;
}

.Title {
  font-size: 44px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $primary-text-color;

  margin: 10px 20px;

  @include media("<tablet") {
    font-size: 32px;
    margin: 10px 10px;
  }
}

.Description {
  font-size: 18px;
  letter-spacing: 1px;
  color: $secondary-text-color;

  margin-top: 0px;
  margin-bottom: 30px;
}

.Input {
  margin: 0px auto;
  width: 80%;
  max-width: 600px;
  // padding: 0 20px;

  @include media("<tablet") {
    width: 85%;
  }
}

.FormError {
  width: 60%;
  padding-top: 30px;
  margin: auto;

  font-size: 24px;

  @include media("<=phone") {
    width: 70%;
    font-size: 20px;
  }
}
