@import "styles/variables.scss";
@import "styles/mixins.scss";

.ClipModal {
  padding: 0px !important;

  width: 70% !important;
  @include media("<large") {
    width: 60% !important;
  }
  @include media("<tablet") {
    width: 90% !important;
  }
}

.CloseBtn {
  top: 0px !important;
  right: 0px !important;
}

.YoutubeVideo {
  padding: 1px;
}

.TwitchClip {
  // padding: 1px;
}

.TwitchVideo {
}

.TextModal {
  padding-top: 20px;
  padding-bottom: 20px;
}
