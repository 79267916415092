@import "styles/mixins.scss";

$cells-per-row: 5;

:export {
  cellsPerRow: $cells-per-row;
}

.Success {
  margin: auto;
  padding: 0px 10px;
  width: 80%;

  h3 {
    margin: 0px;
  }
}

.SubmissionCollage {
  display: inline-block; // to make div same size as children

  .SubmissionCollageRow {
    // border-top: 1px solid rgba(227, 227, 227, 0.8);

    .ShareCollageCell {
      position: relative;

      display: inline;
      // vertical-align: top;

      .ShareCollageImg {
        vertical-align: top;

        aspect-ratio: 1;
        width: 350px / $cells-per-row;

        @include media("<medium") {
          width: 320px / $cells-per-row;
        }
        @include media("<phone") {
          width: 100% / $cells-per-row;
        }
      }

      .ShareCollageIdx {
        position: absolute;
        top: 0;
        left: 0;

        color: white;
        background-color: rgba(41, 36, 43, 0.85);
        font-size: 12px;
        margin: 1px;
        padding: 2px 3px;
        border-radius: 5px;

        font-family: Verdana, Geneva, Tahoma, sans-serif;
        letter-spacing: 0.1px;
      }
    }
  }
}

.SubmissionCollageImg {
  width: 350px;

  @include media("<medium") {
    width: 320px;
  }
  @include media("<phone") {
    width: 100%;
  }
}
