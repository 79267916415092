@import "styles/mixins.scss";

.WidepeepoHappyImg {
  width: 70px;
}

.Success {
  margin: auto;
  padding: 0px 10px;
  width: 80%;

  h3 {
    margin: 0px;
  }
}

.BackBtnContainer {
  margin-left: 10px;
}

.Description {
  padding: 0px 20px;
  width: 80%;
  margin: auto;

  h2 {
    text-transform: none;
  }
}

.FormError {
  width: 60%;
  padding-top: 30px;
  padding-bottom: 20px;
  margin: auto;

  font-size: 24px;

  @include media("<=phone") {
    width: 70%;
    font-size: 20px;
  }
}
