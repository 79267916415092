@import "styles/variables.scss";
@import "styles/mixins.scss";

html {
  height: 100%;

  // https://stackoverflow.com/a/62757438/8802161
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;

  // min-width: 350px;
  // scroll-behavior: smooth;

  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-text-color;
  letter-spacing: 1.2px;

  overflow-y: scroll; /* always show vertical scrollbar, to prevent the pillar images from being jumpy on page load/change*/

  @include media("<=medium") {
    position: absolute; // https://stackoverflow.com/a/32319530
  }

  :global(#root) {
    height: 100%;
  }
}
