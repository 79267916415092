@import "styles/variables.scss";
@import "styles/mixins.scss";

.WelcomeText {
  margin: 0px;
  color: $primary-text-color;
}

.BtnInline {
  display: inline;

  * {
    color: $secondary-text-color;
  }
}
