@import "styles/variables.scss";
@import "styles/mixins.scss";

.Btn {
  color: $primary-text-color;

  background-color: transparent;
  padding: 0px;
  border: 0px solid transparent;

  background-image: url("/assets/btn-hover.png"); // preload image - https://webmasters.stackexchange.com/a/2282

  background-image: url("/assets/btn.png");
  background-size: contain;
  aspect-ratio: 471/111;

  font-family: $font;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  width: 180px;

  @include media(">=tablet") {
    font-size: 18px;
    width: 180px;
  }

  &:hover {
    background-image: url("/assets/btn-hover.png");
    cursor: pointer;

    opacity: 0.97;
  }

  &:active {
    background-image: url("/assets/btn-hover.png");
    cursor: pointer;

    opacity: 1;
  }

  &:disabled {
    cursor: pointer;
  }

  > * {
    width: 100%;
  }

  * {
    color: $primary-text-color;

    :active {
      text-decoration: none;
    }

    :link {
      text-decoration: none;
    }

    :visited {
      text-decoration: none;
    }
  }
}
