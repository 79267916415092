@import "styles/mixins.scss";
@import "styles/variables.scss";

.Details {
  color: $secondary-text-color;

  b {
    color: $primary-text-color;
  }

  .Summary {
    font-size: 18px;
    letter-spacing: 1.2px;
    line-height: 130%;
    font-weight: 600;

    cursor: pointer;
    color: $primary-text-color;

    @include media(">phone") {
      font-size: 22px;
    }

    .ToggleIcon {
      color: $secondary-text-color;
    }
  }

  .Rules {
    margin: 10px 0px;
    padding: 1px 25px;
    background-color: rgba(0, 0, 0, 0.5);

    @include media("<tablet") {
      padding: 1px 15px;
    }

    text-align: left;

    .RulesList {
      margin: 5px;
      // list-style-position: inside;

      font-size: 18px;
      letter-spacing: 1.2px;
      line-height: 110%;

      @include media("<=tablet") {
        font-size: 17px;
        padding-left: 20px;
      }

      @include media("<=phone") {
        font-size: 16px;
        padding-left: 15px;
      }

      .RulesListItem {
        padding: 4px;
      }
    }
  }
}
