@use "styles/mixins.scss" as *;

.TwitchVideoEmbed {
  margin: 0px;
  width: 100%;

  padding-top: 56.25%;
  position: relative;
  height: 0;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
