@import "styles/variables.scss";
@import "styles/mixins.scss";

$play-icon-max-width: 30px;
$play-icon-max-width-small: 20px;

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_radio

.RadioGroup {
  margin: auto;
  max-width: 560px;

  a {
    text-decoration: none;
    color: $radio-input-text-color;

    &:visited,
    &:active,
    &:hover {
      cursor: pointer;
    }
  }
}

/* Hide the browser's default radio button */
.RadioInput {
  position: absolute;
  opacity: 0;
  // filter: alpha(opacity=0);
  // visibility: hidden; // if visibility is set to hidden, cannot use tab to navigate radio buttons
}

.RadioContainer {
  cursor: pointer;
  margin: auto;

  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;

  background-image: url("/components/form/input/assets/voting-hover.png"); // preload image

  background-image: url("/components/form/input/assets/voting.png");
  background-size: cover;
  aspect-ratio: 410/94; // 1230/282
  width: 100%;
  // max-width: 500px;
  max-height: 127px;

  &:hover {
    background-image: url("/components/form/input/assets/voting-hover.png");
    opacity: 0.75;

    // highlight to indicate the choice being hovered
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    // highlight to indicate the choice being clicked
    background-color: rgba(255, 255, 255, 0.05);

    background-image: url("/components/form/input/assets/voting-hover.png");
    opacity: 1;
  }
}

input:checked {
  + .RadioContainer {
    background-image: url("/components/form/input/assets/voting-hover.png");
    opacity: 0.9;
    // background-color: rgba(255, 255, 255, 1);
  }
}

.ImgContainer {
  display: contents;

  .RadioImg {
    padding-left: 2.5%;
    height: 89%;

    aspect-ratio: 1;
    // object-fit: contain; // re-enable to fit original image aspect ratio

    max-width: 200px;
    max-height: 115px;
  }

  .PlayBtn {
    position: relative;
    z-index: $z-index-radio-play;

    top: 30%;
    right: 32px;
    max-width: $play-icon-max-width;
    @include media("<=phone") {
      right: 21px;
      max-width: 20px;
    }

    // &.Hide {
    //   opacity: 0;
    // }
  }
}

.RadioSpan {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  text-align: center;

  font-size: 13px;
  text-transform: uppercase;
  font-weight: $font-weight-faux-bold;

  margin-left: 10px;
  margin-right: 10px;

  &.Offset {
    left: -$play-icon-max-width / 2;
    @include media("<=phone") {
      left: -$play-icon-max-width-small / 2;
    }
  }
}

.RadioSpanText {
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
  @supports (-webkit-line-clamp: 2) {
    // Ellipsis after 2 lines
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  font-size: 17px;
  letter-spacing: 1.2px;

  @include media("<=small") {
    font-size: 17px;
  }

  @include media(">=phone") {
    font-size: 20px;
    font-weight: $font-weight-faux-bold;

    letter-spacing: 2.5px;
  }

  @include media(">=tablet") {
    font-size: 22px;
  }
}

.ValidInput {
  position: absolute;
  right: 15px;

  &.Offset {
    right: 15px -$play-icon-max-width / 2;
    @include media("<=phone") {
      right: 10px -$play-icon-max-width-small / 2;
    }
  }
}
